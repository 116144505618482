import React from 'react';
//import Slider from 'react-slick';
import Carousel from '../../Carousel'
import './StaffSlider.scss'

export const StaffSlider = props => {
  const {  staffList } = props;
  const sliderSettings = {
      nav: false,
      infinite: true,
      container: 'staff-slick-slider',
      autoplayButtonOutput: false,
      mode:'gallery',
      speed: 500,
      autoplayTimeout:2000,
    };
  return (
    <Carousel settings={sliderSettings}>
      {staffList && staffList.map( (item, index) => (
      <div key={index} className="profile">
        <img src={item.images.url} alt="" />
      </div>
      ))}
    </Carousel>
  );
};
