import React from 'react';

export const BackgroundImage = props => {
  const { imageUrl, className, style } = props;

  const backgroundStyle = {backgroundImage: `url(${imageUrl})`};
  const customStyle = {...backgroundStyle, ...style};
  const customClass = className ? `image-background ${className}` : 'image-background';
  return (
    <div className={customClass} style={customStyle} />
  );
};
/*
BackgroundImage.propTypes = {
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  backgroundStyleOptions: PropTypes.string,
  style: PropTypes.object
};
*/
