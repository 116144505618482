import React from 'react';
import { Title } from '../Content/Title';
import { Video } from '../Content/Video';
import './QuoteHeader.scss'

export const QuoteHeader = props => {
  const { item, settings, preview } = props
  const { title, clientName } = item
  const { heroVideo } = settings
  return (
    <section className="page-hero page-section">
      <div className="video-container">
        <div className={`video${preview ? ' preview' : ''}`}>
          {heroVideo && <Video src={heroVideo} preview={preview} />}
        </div>
        <section className="page-hero-header">
          <Title title={clientName} type="h1" />
          <p>{title}</p>
          <span className="logo">BirdBrain</span>
        </section>
      </div>
      <section className={`page-hero-scroll-down${preview ? ' preview' : ''}`}>
        <p>SCROLL DOWN</p>
        <div className="line-down" />
      </section>
    </section>
  );
};
