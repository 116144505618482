import React from "react"
import * as Layout from './';

const LayoutComponent = ({ componentName, item, settings, quote, acceptQuote, signedOff, preview }) => {
  const ComponentName = Layout[componentName];
  const componentTitle = componentName ? componentName : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The APP component <strong>"{componentTitle}"</strong> is missing. <span role="img" aria-label="Smile">&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName item={item} settings={settings} quote={quote} acceptQuote={acceptQuote} signedOff={signedOff} preview={preview} />
    );
  }

}

export default LayoutComponent
