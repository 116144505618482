import React from 'react';
import OnVisible from 'react-on-visible';
import { Title } from '../Content/Title';
import { RenderContent } from '../Content/RenderContent';
import './QuoteBlock.scss'

export const QuoteBlock = props => {
  const {preview} = props
  const { title, content, print } = props.item;
  return (
    <section className={`quote-block ${print ? 'print' : 'print-exclude'}`}>
      <OnVisible className={`${preview === true ? '' : 'fade-in' }`} percent={10}>
        <div className="wrapper">
          <div className="quote-content">
            <Title title={title} tag="h2" />
            <RenderContent content={content} />
          </div>
        </div>
      </OnVisible>
    </section>
  );
};
