import React from "react"
import axios from 'axios'
import { Loading, Close, Edit } from '../../images'
import LayoutComponent from './Layout/LayoutComponent'
import './fonts.scss'
import './index.scss'
import { PrintTools } from './Common/PrintTools'
import { Footer } from './Common/Footer'
import { isBrowser, sendSlackMessage } from '../../utils/Functions'
import { getUser } from '../../utils/auth'

const QUOTE_USERS = [
  '@loges',
  '@thegamechanger',
  '@david',
  '@mollart'
];

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: [],
      error: null,
      isLoaded: false,
      settings: [],
      signedOff: false,
      user: getUser()
    }
  }

  markQuoteAsRead = ( postId ) => {
    console.log('Read quote:', postId)
    const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/quotes/v2/status?type=read&id=${postId}`;
    console.log('URL Check: ',wpPostMetaURL)
    return axios.get(wpPostMetaURL)
    .then(response => {
      console.log('Mark as read response:', response.data.data)
      if (response.data.data.action === 'slack') {
        //slackNotification(`Quote being read ${response.data.data.quoteNumber && `QN-${response.data.data.quoteNumber}`}`, `:heres_johnny: The *${response.data.data.client}* quote is currently being read. <${response.data.data.previewLink}>`, SLACK_USERS)
        //const title = `Quote being read ${response.data.data.quoteNumber && `QN-${response.data.data.quoteNumber}`}`
        const message = `Quote being read ${response.data.data.quoteNumber && `QN-${response.data.data.quoteNumber}`} :eyes: The *${response.data.data.client}* quote is currently being read. <${response.data.data.previewLink}>`
        this.props.preview !== true && sendSlackMessage('quote', message) 
        .then(response => {
          console.log('Response:',response)
        })
        .catch();
      }
      return null;
    })
    .catch(error => console.error('submitForm Error', error));
  }

  acceptQuote = ( postId ) => {
    console.log('Accept quote:', postId)
    const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/quotes/v2/status?type=signedOff&id=${(postId)}`;
    //console.log('URL Check: ',wpPostMetaURL)
    return axios.get(wpPostMetaURL)
    .then(response => {
      //console.log('response:',response.data.data)
      if (response.data.data.action === 'slack') {
        //slackNotification(`New quote approval ${response.data.data.quoteNumber && `QN-${response.data.data.quoteNumber}`}`, `*${response.data.data.client}* has been approved :moneybag: <${response.data.data.previewLink}>`, SLACK_USERS)
        //const title = `Quote approval ${response.data.data.quoteNumber && `QN-${response.data.data.quoteNumber}`}`
        const message = `Quote approval ${response.data.data.quoteNumber && `QN-${response.data.data.quoteNumber}`} :moneybag: ${response.data.data.client}* has been approved! <${response.data.data.previewLink}>`
        //const slackUsers = QUOTE_USERS
        sendSlackMessage('quote', message)
      }
      this.setState({
        signedOff: true
      })
    })
    .catch(error => console.error('submitForm Error', error));
  }

  loadData() {
    const url = `${process.env.WP_ADDRESS}/wp-json/wp/v2/quotes?slug=${this.props.splat}`
    const settingsUrl = `${process.env.WP_ADDRESS}/wp-json/acf/v2/options`
    console.log('url:',url)
    axios.all([
      axios.get(url),
      axios.get(settingsUrl)
    ])
    .then(axios.spread((res, setting) => {
      if (res.data[0]) {
        console.log('res.data[0]:',res.data[0])
        this.setState({
          post: res.data[0],
          settings: setting.data.acf,
          isLoaded: true,
          signedOff: res.data[0].acf.signedOff
        })
        //Mark as read
        if (isBrowser) {
          const urlParams = window.location.search ? new URLSearchParams(window.location.search) : null;
          const preview = this.props.preview ? true : urlParams && urlParams.get('preview') ? true : false;
          preview !== true && res.data[0].acf.read !== true && this.markQuoteAsRead(res.data[0].id);
        }

      }else{
        this.setState({
          isLoaded: true,
          error: true,
        })
      }
    }))
    .catch((err) => {
      console.log('error:', err)
      this.setState({
        isLoaded: true,
        error: true
      })
    })
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  render() {
    const { post, isLoaded, error, settings, signedOff, user } = this.state
    const { splat, closeQuote, preview = false } = this.props
    const slug = splat
    const { caseStudies } = settings
    const { acf = [], id } = post
    const { layout = [] } = acf
    //console.log('page preview:',preview)

    if ( !isLoaded ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return (
        <div className="error-msg">
          <div>
            <h4>This provided link has expired.</h4>
            <p>A new link can be provided by contacting the original sender.</p>
          </div>
        </div>
      )
    }



    return (

        <div className="quotes">

          {preview &&
            <div className="address">
              <div className="inner">
                <span>
                  Client Link: <a href={`${process.env.QUOTE_URL}/quotes/${this.props.splat}`} rel="noopener noreferrer" target="_blank">{process.env.QUOTE_URL}/quotes/{this.props.splat}</a> (This link will send the author a slack notification when read.)
                </span>
                <button title="Close Quote" onClick={() => closeQuote()} className="close"><Close/></button>
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.WP_ADDRESS}/wp-admin/post.php?auto_login=${user.cookie}&post=${id}&action=edit&classic-editor`} title="Edit" className="edit"><Edit/></a>
              </div>
            </div>
          }

          {layout && layout.map((item, index) =>
            <LayoutComponent
              key={index}
              item={item}
              acceptQuote={this.acceptQuote}
              settings={settings}
              componentName={item.acf_fc_layout}
              quote={post}
              signedOff={signedOff}
              preview={preview}
            />
          )}

          {acf.showCaseStudies && caseStudies ?
            <LayoutComponent
              item={caseStudies}
              settings={settings}
              componentName="ClientTestimonials"
              quote={acf}
              preview={preview}
            />
            : ''}

          {settings.layout.map((item, index) =>
            <LayoutComponent
              key={index}
              item={item}
              settings={settings}
              componentName={item.acf_fc_layout}
              quote={acf}
              preview={preview}
              />
          )}

          <PrintTools slug={slug} />

          <Footer settings={settings} />

        </div>
    )
  }
}

export default Index
