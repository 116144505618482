import React from 'react';
import { IconLink} from '../Content/IconLink';
import './Footer.scss'

export const Footer = props => {
  const { settings } = props;
  const { socialLinks } = settings;

  return (
    <footer className="content-info" role="contentinfo">
      <div className="wrap">
        <section className="left">
          <div className="logo-container">
            <IconLink href={socialLinks[0].url} icon="birdbrain">BirdBrain</IconLink>
          </div>
        </section>
        <section className="right">
          <div className="logo-container">
            {socialLinks && socialLinks.map((social, key) => (
              <IconLink key={key} href={social.url} icon={social.type}>{social.type}</IconLink>
            ))}
          </div>
        </section>
      </div>
    </footer>
  );
};
