import React from 'react';
import OnVisible from 'react-on-visible';
import { Title } from '../Content/Title';
import { RenderContent } from '../Content/RenderContent';
import { StaffSlider } from '../Common/StaffSlider';
import './BirdbrainIntroduction.scss'

export const BirdbrainIntroduction = props => {
  const {item, settings, preview } = props
  const { header, content } = item;
  const { staffImages } = settings
  return (
    <section className="birdbrain-introduction">
      <OnVisible className={`${preview === true ? '' : 'fade-in' }`} percent={10}>
        <div className="birdbrain-introduction-content">
          <Title title={header} tag="h2" />
          <RenderContent content={content} />
        </div>
      </OnVisible>
      <StaffSlider staffList={staffImages} />
      <div id="your-quote" />
    </section>
  );
};
