import React from 'react';
import OnVisible from 'react-on-visible';
import { Title } from '../Content/Title';
import { RenderContent } from '../Content/RenderContent';

export const ImportantInfo = props => {
  const { preview } = props
  const { title, content } = props.item;
  return (
    <section className="quote-block pad-top pad-bottom page-section">
      <OnVisible className={`${preview === true ? '' : 'fade-in' }`} percent={10}>
        <div className="wrapper">
          <div className="quote-content">
            <Title title={title} tag="h2" />
            <RenderContent content={content} />
          </div>
        </div>
      </OnVisible>
    </section>
  );
};
