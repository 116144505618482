import React from 'react';
import './Video.scss'

export const Video = props => {
  const {
    src,
    type = 'video/mp4',
    disableOnTabletDown = true,
    className,
  } = props;
  const classNameStr = `video-background${disableOnTabletDown && ' disable-tablet-down'} ${className}`
  if (src) {
    return (
      <video className={classNameStr} autoPlay muted loop style={{top: 0}}>
        <source src={src} type={type} />
      </video>
    );
  } else {
    return null;
  }

};
/*
Video.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
  disableOnTabletDown: PropTypes.bool
};
*/
