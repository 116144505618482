import React from 'react'
import './PrintTools.scss'

export const PrintTools = props => {
  const { slug } = props;
  return (
    <section className="print-button">
      <a href={`${process.env.WP_ADDRESS}/quotes/${slug}/pdf`} target="_blank" rel="noopener noreferrer"><span /></a>
    </section>
  );
};
