import React from 'react';
import OnVisible from 'react-on-visible';
import { Title } from '../Content/Title';
import { RenderContent } from '../Content/RenderContent';
import './LineItems.scss'

export const LineItems = props => {
  const { preview } = props
  const { sectionTitle, columnOneTitle, columnTwoTitle, line, underline } = props.item;
  return (
    <section className="line-items pad-top pad-bottom page-section">
      <OnVisible className={`${preview === true ? '' : 'fade-in' }`} percent={10}>
        <div className="wrapper">
          <div className={`inner ${underline ? 'underline' : ''}`}>
            {sectionTitle && <Title title={sectionTitle} tag="h2" />}
            <div className="row">
              <div className="col">

              {columnOneTitle && <Title title={columnOneTitle} tag="h3" />}

              {line && line.map((item, key) => {
                const { columnOne } = item
                return (
                  <div className="item" key={key}>
                  <RenderContent content={columnOne} />
                  </div>
                )
              })}
              </div>
              <div className="col">
              {columnTwoTitle && <Title title={columnTwoTitle} tag="h3" />}
              {line && line.map((item, key) => {
                const { columnTwo } = item
                return (
                  <div className="item" key={key}>
                  <RenderContent content={columnTwo} />
                  </div>
                )
              })}
              </div>
            </div>
          </div>
        </div>
      </OnVisible>
    </section>
  );
};
