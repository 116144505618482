import React from 'react';
import OnVisible from 'react-on-visible';
//import Slider from 'react-slick';
import Carousel from '../../Carousel'
import { Title } from '../Content/Title';
import { RenderContent } from '../Content/RenderContent';
import './ClientTestimonials.scss'

export const ClientTestimonials = props => {
  const { item, preview } = props;
  const { title, testimonials } = item
  const sliderSettings = {
      nav: true,
      infinite: false,
      container: 'testimonail-slick-slider',
      autoplayButtonOutput: false,
      autoplayPosition: 'bottom',
      mode:'gallery',
      speed: 500,
      autoplay: false
    };
  return (
    <section className="quote-block testimonials pad-top pad-bottom page-section" id="our-clients">
      <OnVisible className={`${preview === true ? '' : 'fade-in' }`} percent={10}>
        <div className="wrapper">
          <div className="testimonial-content">
            <h2>{title}</h2>
            <Carousel settings={sliderSettings}>
              {testimonials && testimonials.map(item => (
                <article key={item.client}>
                  <Title title={item.client} tag="h3" />
                  <RenderContent content={item.testimonial} />
                  <footer className="client-details">
                    <img className="client-image" src={item.image.sizes.thumbnail} alt={item.title} />
                    <span className="client-name">{item.title}</span>
                  </footer>
                </article>
              ))}
            </Carousel>
          </div>
        </div>
      </OnVisible>
    </section>
  );
};
