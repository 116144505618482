import React from 'react';

const handleWpImage = (image, minWidth, minHeight) => {
  const { sizes, url } = image;

  if (!minWidth && !minHeight) {
    return url;
  }

  // 1. map object keys to their value
  // 2. reduce all key/value pairs into their sizes
  const fixedSizes = Object.keys(sizes)
    .map(k => {
      return {key: k, value: sizes[k]};
    })
    .reduce((accumulatedSizes, size) => {
      const keyParts = size.key.split('-');
      const newKey = keyParts[0];
      const accumulatedSizePart = accumulatedSizes[newKey] || { key: newKey };

      if (keyParts.length === 1) {
        // if we have 1 part, this is the URL
        accumulatedSizePart.url = size.value;
      } else {
        // else we have a dimention
        const axis = keyParts[1];
        accumulatedSizePart[axis] = size.value;
      }

      accumulatedSizes[newKey] = accumulatedSizePart; // eslint-disable-line no-param-reassign
      return accumulatedSizes;
    }, {});

  // 3. convert the object we used to create the sizes object
  // 4. sort small to big
  const sortedSizes = Object.keys(fixedSizes)
    .map(k => fixedSizes[k])
    .sort((l, r) => {
      // sort by area consumed by the image
      return l.height - r.height;
    });

  // 5. iterate over sortedSizes to find the smallest img url that satisfies user request
  let resultUrl = url;
  for (const size of sortedSizes) {
    if ((!minWidth || size.width >= minWidth) && (!minHeight || size.height >= minHeight)) {
      resultUrl = size.url;
      break;
    }
  }

  return resultUrl;
};

export const withWordpressImage = (config = {}) => {
  return (WrappedComponent) => {
    return (props) => {
      const { wpImage, minWidthRes = config.minWidthRes, minHeightRes = config.minHeightRes, ...otherProps} = props;
      const imageUrl = handleWpImage(wpImage, minWidthRes, minHeightRes);
      return (
        <WrappedComponent imageUrl={imageUrl} {...otherProps} />
      );
    };
  };
};
