import React from 'react';
import OnVisible from 'react-on-visible';
import { Title } from '../Content/Title';
import { RenderContent } from '../Content/RenderContent';
import { Smile } from '../../../images'
import './SignOff.scss'

const getApproveClassName = (signedOff, quote) => {
  if (quote && quote.loading) return 'button submitting';
  if (signedOff) return 'button approved';
  if (!signedOff && quote && quote.signedOff) return 'button approved';
  return 'button';
};

const getApproveButtonText = (signedOff, quote) => {
  if (quote && quote.loading) return 'Approving';
  if (signedOff) return <span>Quote Approved<Smile/></span>;
  if (!signedOff && quote && quote.signedOff) return <span>Quote Approved</span>;
  return <span>Approve Quote</span>
};



export const SignOff = props => {
  const { item, quote, acceptQuote, signedOff, preview } =  props
  const { acf, id } = quote
  const { quoteNumber } = acf
  const { title, content, pricingTable, hideButton } = item;

  const handleAacceptQuote = (event) => {
    event.preventDefault();
    if (!signedOff) acceptQuote(id);
    else alert('Already approved');
  };

  return (
    <section className="quote-block sign-off">
      <div id="pricing" />
      <OnVisible className={`${preview === true ? '' : 'fade-in' }`} percent={10}>
        <div className="wrapper">
          <div className="quote-content sign-off-content">
            <Title title={title} tag="h2" />
            {quoteNumber && <p>Quote number: <strong>{quoteNumber && `QN-${quoteNumber}`}</strong></p>}
            <RenderContent content={content} />
          </div>
          <div className="quote-table">
            {pricingTable && pricingTable.map((row, index) => (
              row.rowItemName && row.rowItemPrice ?
                <div key={index} className="row">
                  <div className="row-item name">
                    {row.rowItemName}
                  </div>
                  <div className="row-item price">
                    {row.rowItemPrice}
                  </div>
                </div>
              :
                <div key={index}/>
            ))}
          </div>
          {!hideButton ?
            <div className="sign-off">
              <button className={getApproveClassName(signedOff, quote)} disabled={signedOff ? true : false} onClick={(event) => handleAacceptQuote(event)}>
                {getApproveButtonText(signedOff, quote)}
              </button>
            </div>
          : <span />}
        </div>
      </OnVisible>
    </section>
  );
};
