import React from 'react';
import { withWordpressImage } from '../utils/wordpressImage';
import { BackgroundImage } from '../Content/BackgroundImage';
import './Image.scss'

const PageDividerImage = withWordpressImage({minWidthRes: 1200})(BackgroundImage);

export const Image = props => {
  const { image, height } = props.item;

  const styles = { height: `${height}px` };
  return (
    <section className="quote-block page-divider page-section-inverted">
      <div className="page-section-midpoint">
        <PageDividerImage wpImage={image} style={styles} />
      </div>
    </section>
  );
};
